<template lang="pug">
v-card(v-if="Object.keys(statement).length").pa-3
  v-card-title.d-flex.justify-space-between.w-100
    div.text-uppercase {{ $t(`${Object.keys(statement.behavior)[0]}-${type}`) }}
      span(v-if="checkAccess('backOffice')") (ID: {{ statement.id }})
    ControlButtons(:controlButtons="controlButtons").justify-end
  v-progress-linear(v-if="isLoading" indeterminate color="cyan")
  v-card-text
    v-divider
    Info(v-if="statement?.behavior?.viewInfoBlock")
    Edit(v-else-if="statement?.behavior?.viewEditBlock")
    ViewPhotoList(
      v-else-if="statement?.behavior?.viewFilesBlock"
      :sailorDocument="statement"
      :documentType="type")
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { viewDetailedComponent } from '@/mixins/main'
import { checkAccess } from '@/mixins/permissions'
export default {
  components: {
    Info: () => import('./Info.vue'),
    Edit: () => import('./Edit.vue'),
    ViewPhotoList: () => import('@/components/atoms/ViewPhotoList.vue'),
    ControlButtons: () => import('@/components/atoms/ControlButtons.vue')
  },
  data () {
    return {
      checkAccess,
      viewDetailedComponent,
      type: 'crewingManagerStatements',
      controlButtons: [
        {
          id: 1,
          name: 'mdi-information-outline',
          action: () => viewDetailedComponent(this.statement, 'viewInfoBlock'),
          color: '#42627e',
          tooltip: 'documentIconControl.info'
        },
        {
          id: 2,
          name: 'mdi-pen',
          action: () => viewDetailedComponent(this.statement, 'viewEditBlock'),
          checkAccess: () => checkAccess(this.type, 'edit', this.statement),
          color: '#42627e',
          tooltip: 'documentIconControl.edit'
        },
        {
          id: 3,
          name: 'mdi-image-outline',
          action: () => viewDetailedComponent(this.statement, 'viewFilesBlock'),
          checkAccess: () => checkAccess(this.type, 'files', this.statement),
          color: '#42627e',
          tooltip: 'documentIconControl.files'
        },
        {
          id: 4,
          name: 'mdi-close',
          action: () => this.$router.go(-1),
          color: '#42627e',
          tooltip: 'documentIconControl.close'
        }
      ]
    }
  },
  computed: {
    ...mapState({
      statement: state => state.statement.crewingManagerStatementById,
      isLoading: state => state.statement.isLoading
    })
  },
  mounted () {
    this.getCrewingManagerStatement({ ...this.$route.params })
  },
  methods: {
    ...mapActions(['getCrewingManagerStatement'])
  }
}
</script>
